import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import SubCategories from 'src/components/category/SubCategories';
import CategoriesProducts from 'src/components/category/CategoriesProducts';
import CategoriesForm from 'src/components/category/Form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import CategoryMedia from 'src/components/category/CategoryMedia';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const urlApi = '/categories';
const urlRouter = '/category';

export async function LoaderCategoryUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`, {
      params: {
        'g[0]': 'cat:read',
        'g[1]': 'cat:categories',
        'g[2]': 'cat:media',
      }
    }).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}

// const formReducer = (state, event) => {
//   return {
//     ...state,
//     [event.target.name]: event.target.value
//   }
// }

const CategoryForm = ({user_data}) => {
  if(!Permissions(user_data, 'Category', 'EDIT_CATEGORY')){
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  // console.log('myData', myData);

  // Викликає popup з власного компонента
  const popup = MyToastify();

  useEffect(() => {
    setMyData(data);
  }, [data]);

  return (
    <div className="mb-3">
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      <div className='row'>
        <div className='col'>

        </div>
        <div className='col d-flex justify-content-end'>

          {(myData != undefined) ? <LangugesPanel data={myData}/>: ''}
        </div>
      </div>
      <CategoriesForm data={myData} type='page' toastifySuccess={popup.toastifySuccess} />
      <hr />
      {(myData != undefined) ? <SubCategories categories={myData.categories} parent={myData.id} parent_name={myData.name} toastifySuccess={popup.toastifySuccess} /> : ''}
      <hr />
      {(myData != undefined) ? <CategoriesProducts category_id={myData.id} toastifySuccess={popup.toastifySuccess} /> : ''}

      {/* Фарма: назва, синонім, статус, тип та опис */}
      <CategoriesForm data={myData} setMyData={setMyData} type='page' toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />

      {/* Підкатегорії: додавання підкатегорій та список */}
      {
        (myData != undefined) &&
        <>
          <hr className="mb-4" />
          <SubCategories categories={myData.categories} parent={myData.id} parent_name={myData.name} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }

      {/* Товари: додавання товарів та список */}
      {
        (myData != undefined) &&
        <>
          <hr className="mb-4" />
          <CategoriesProducts category_id={myData.id} toastifySuccess={popup.toastifySuccess} />
        </>
      }
    </div>
  );
};

export default CategoryForm;
